:root {
  --c-border: #000000;
  --c-panel-background: #FFFFFF;
  --c-disabled: #EEEEEE;
  --c-focus: #000000;
  --c-field-disabled: var(--c-disabled);
  --c-field-focus: var(--c-focus);
  --c-input-background: var(--c-panel-background);
  --c-input-border: var(--c-border);
}

.cookie-audit__form {
  width: 100%;
  box-sizing: border-box;
}
.cookie-audit__form th {
  font-weight: bold;
}

.cookie-audit__cookies-col {
  width: 25%;
}

.cookie-audit__name {
  font-weight: bold;
  width: 75%;
}

.cookie-list,
.richtext .cookie-list {
  margin-bottom: 0;
}
.cookie-list li,
.richtext .cookie-list li {
  margin-bottom: 0;
  padding: 0;
}
.cookie-list li::before,
.richtext .cookie-list li::before {
  display: none;
}

.cookie-bar {
  font-size: 13px;
  background: white;
  z-index: 3000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}
.cookie-bar__wrap {
  margin: 0 auto;
  max-width: 90%;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 600px) {
  .cookie-bar__wrap {
    flex-wrap: wrap;
  }
}
.cookie-bar__copy {
  margin: 5px 10px;
  font-size: 0.9230769231em;
  flex: 1 1 auto;
}
@media (max-width: 600px) {
  .cookie-bar__copy {
    width: 100%;
  }
}
.cookie-bar__action {
  flex: 0 0 auto;
  margin: 5px 10px;
}
.cookie-preferences {
  color: #000000;
}
.cookie-preferences * {
  box-sizing: border-box;
}
.cookie-preferences:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3001;
  background: rgba(0, 0, 0, 0.4);
}
.cookie-preferences__form {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 0px;
  font-size: 13px;
  position: fixed;
  width: 600px;
  min-height: 350px;
  max-width: 90vw;
  max-height: 90vh;
  z-index: 3002;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: scroll;
}
.cookie-preferences__title {
  flex: 0 0 100%;
  margin: 0;
  padding: 15px;
  font-size: 1.6923076923em;
}
.cookie-preferences__nav {
  box-sizing: border-box;
  flex: 1 0 30%;
  background: #FFFFFF;
}
.cookie-preferences__nav-link {
  padding: 13px 15px;
  display: block;
  border-bottom: 1px solid white;
  color: #fff;
}
.cookie-preferences__nav-link--active {
  background: #ffffff;
  color: #000000;
}
.cookie-preferences__panels {
  box-sizing: border-box;
  flex: 2 0 70%;
  background: #ffffff;
}
.cookie-preferences__panel {
  padding: 30px;
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}
.cookie-preferences__panel--active {
  display: flex;
}
.cookie-preferences__panel-title {
  flex: 1 0 auto;
  vertical-align: middle;
  margin: 0 0 0.5em;
  font-size: 1.3076923077em;
}
.cookie-preferences__panel-description {
  flex: 1 0 100%;
  border-top: 1px solid #bdbdbd;
  padding-top: 15px;
  margin-top: 15px;
}
.cookie-preferences__switch {
  flex: 0 0 auto;
  margin: 0 0 0 auto;
  vertical-align: middle;
}
.cookie-preferences__switch input {
  display: none;
}
.cookie-preferences__switch-icon {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  background: #FFFFFF;
  width: 56px;
  height: 32px;
  border-radius: 15px;
  border: 1px solid #bdbdbd;
}
.cookie-preferences__switch-icon:before {
  display: block;
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #bdbdbd;
}
.cookie-preferences__switch-label {
  vertical-align: middle;
  display: inline-block;
}
.cookie-preferences__switch-label:after {
  content: attr(data-inactive);
}
.cookie-preferences__switch input:checked ~ .cookie-preferences__switch-icon:before {
  left: auto;
  right: 3px;
}
.cookie-preferences__switch input:checked ~ .cookie-preferences__switch-label:after {
  content: attr(data-active);
}
.cookie-preferences__noswitch {
  vertical-align: middle;
  display: inline-block;
  line-height: 32px;
  margin: 0;
}
.cookie-preferences__footer {
  padding: 15px;
  flex: 0 0 100%;
  background: #FFFFFF;
  display: flex;
  align-items: center;
}
.cookie-preferences__footer button {
  margin-left: auto;
}
.cookie-preferences__statement {
  font-size: 0.9230769231em;
  color: #fff;
}
.cookie-preferences__submit {
  font-size: 1.0769230769em;
}

.cookie-bar {
  width: 100%;
  border-top: 1px solid #bdbdbd;
}
.cookie-bar__wrap {
  max-width: 1280px;
}
.cookie-bar__copy p {
  margin-bottom: 0;
}
.cookie-bar .cookie-bar__action--preferences {
  color: #000000;
}

.cookie-bar--ghost {
  position: relative;
  visibility: hidden;
  z-index: 0;
}

.cookie-preferences__panels {
  min-height: 250px;
}

.cookie-preferences:before {
  z-index: 10000;
}
.cookie-preferences .cookie-preferences__form {
  overflow: auto;
  z-index: 10001;
}

.cookie-preferences__title {
  border-bottom: 1px solid #bdbdbd;
}

.cookie-preferences__nav {
  border-right: 1px solid #bdbdbd;
}

.cookie-preferences__nav-link--active:after {
  display: block;
  content: "";
  background: #ffffff;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -2px;
}

.cookie-preferences__statement {
  color: #000000;
  text-decoration: none;
}

.cookie-preferences__nav-link {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #bdbdbd;
  position: relative;
}

.cookie-preferences__switch-label {
  margin-right: 3px;
}

.cookie-preferences__switch-icon:before {
  transition: background-color 0.3s ease, left 0.3s ease;
}

.cookie-preferences__switch input:checked ~ .cookie-preferences__switch-icon:before {
  background-color: #000000;
  right: auto;
  left: 27px;
}

.cookie-preferences__footer {
  border-top: 1px solid #bdbdbd;
}

a.cookie-bar__action--accept,
.cookie-preferences__submit {
  padding: 10px 15px;
  vertical-align: middle;
  white-space: normal;
  background: #FFFFFF;
  color: #000000;
  border: 1px solid #000000;
  text-decoration: none;
  border-radius: 2px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}
a.cookie-bar__action--accept:hover,
.cookie-preferences__submit:hover {
  color: #FFFFFF;
  background-color: #000000;
  text-decoration: none;
}

.cookie-bar__action {
  text-decoration: none;
}
.cookie-bar__action:hover {
  text-decoration: underline;
}

.cookie-preferences__submit {
  min-width: 100px;
}

.cookie-audit__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 16px;
}

.cookie-audit__intro {
  margin-bottom: 20px;
}

.cookie-audit__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid #bdbdbd;
}
.cookie-audit__table th, .cookie-audit__table td {
  padding: 5px 10px;
  border: 1px solid #bdbdbd;
  font-style: normal;
}
@media screen and (max-width: 42.5em) {
  .cookie-audit__table th:first-child, .cookie-audit__table td:first-child {
    width: 75%;
  }
}
@media screen and (max-width: 42.5em) {
  .cookie-audit__table th:last-child, .cookie-audit__table td:last-child {
    width: 25%;
  }
}
@media screen and (max-width: 42.5em) {
  .cookie-audit__table th > *, .cookie-audit__table td > * {
    word-wrap: anywhere;
  }
}
.cookie-audit__table td {
  font-size: 14px;
  font-size: 0.875rem;
}

.cookie-audit__name {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.3;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 5px;
  line-height: 1.7;
  width: auto;
}
@media screen and (max-width: 62.4375em) {
  .cookie-audit__name {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}