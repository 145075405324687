// Settings
@import 'settings/variables';
@import 'settings/breakpoints';

// Tools
@import 'tools/mixins';
@import 'tools/typography';

$cookie-fg: $c-background;
$cookie-nav-bg: $c-white;
$cookie-footer-bg: $c-white;
$cookie-border: $c-border;
$cookie-fontcolor: $c-black;
$cookie-switch-fg: $c-border;
$cookie-switch-bg: $c-white;

// Components
@import "cookie-centre/cookie-settings";
@import "cookie-centre/cookie-audit";
@import "cookie-centre/cookie-bar";
@import "cookie-centre/cookie-preferences";


.cookie-bar {
    width: 100%;
    border-top: 1px solid $cookie-border;

    &__wrap {
        max-width: 1280px;
    }
    &__copy {
        p {
            margin-bottom: 0;
        }
    }
    .cookie-bar__action--preferences {
        color: $c-black;
    }
}

.cookie-bar--ghost {
    position: relative;
    visibility: hidden;
    z-index: 0;
}

.cookie-preferences__panels {
    min-height: 250px;
}

.cookie-preferences {
    &:before {
        z-index: 10000;
    }
    .cookie-preferences__form {
        overflow: auto;
        z-index: 10001;
    }
}

.cookie-preferences__title {
    border-bottom: 1px solid $c-border;
}

.cookie-preferences__nav {
    border-right: 1px solid $c-border;
}

.cookie-preferences__nav-link--active:after {
    display: block;
    content: "";
    background: $c-background;
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -2px;
}

.cookie-preferences__statement {
    color: $c-black;
    text-decoration: none;
}

.cookie-preferences__nav-link {
    color: $c-black;
    text-decoration: none;
    border-bottom: 1px solid $c-border;
    position: relative;
}

.cookie-preferences__switch-label {
    margin-right: 3px;
}

.cookie-preferences__switch-icon:before {
    transition: background-color .3s ease, left .3s ease;
}

.cookie-preferences__switch input:checked ~ .cookie-preferences__switch-icon:before {
    background-color: $c-brand;
    right: auto;
    left: 27px;
}

.cookie-preferences__footer {
    border-top: 1px solid $c-border;
}

a.cookie-bar__action--accept,
.cookie-preferences__submit {
    padding: 10px 15px;
    vertical-align: middle;
    white-space: normal;
    background: $c-white;
    color: $c-brand;
    border: 1px solid $c-brand;
    text-decoration: none;
    border-radius: 2px;
    transition: background-color .3s ease, color .3s ease, border-color .3s ease;

    &:hover {
        color: $c-white;
        background-color: $c-brand;
        text-decoration: none;
    }
}

.cookie-bar__action {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.cookie-preferences__submit {
    min-width: 100px;
}

.cookie-audit {}

    .cookie-audit__title {
        @include h4;

        margin-bottom: 16px;
    }

    .cookie-audit__intro {
        margin-bottom: 20px;
    }

    .cookie-audit__table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 40px;
        border: 1px solid $c-border;

        th, td {
            padding: 5px 10px;
            border: 1px solid $c-border;
            font-style: normal;

            &:first-child {

                @include breakpoint(phablet) {
                    width: 75%;
                }
            }

            &:last-child {

                @include breakpoint(phablet) {
                    width: 25%;
                }
            }

            > * {

                @include breakpoint(phablet) {
                    word-wrap: anywhere;
                }
            }
        }

        td {
            @include rem(14px);
        }
    }

    .cookie-audit__name {
        @include small-subtitle;
        @include rem(16px);

        margin-bottom: 5px;
        line-height: 1.7;
        width: auto;
    }
